import React, { useEffect } from 'react';

import Box from '@/atoms/Box';
import Button from '@/atoms/Button';
import Heading from '@/atoms/Heading';
import Link from '@/atoms/Link';
import Text from '@/atoms/Text';
import Mascot from '@/components/Mascot';
import { NoticeWrapper, Notice } from '@/components/Notice';

import useLocalStorage from '@/hooks/useLocalStorage';

const CookieNotice = () => {
  const [isAccepted, setAccepted] = useLocalStorage(
    'cookies_accepted',
    undefined
  );

  useEffect(() => {
    if (typeof isAccepted === 'undefined') {
      setAccepted(false);
    }
  }, [isAccepted, setAccepted]);

  const giveConsent = type => {
    setAccepted(type);

    if (window?.dataLayer) {
      window.dataLayer.push({
        event: 'cookie-consent',
      });
    }
  };

  if (isAccepted === false) {
    return (
      <NoticeWrapper
        flexWrap={['wrap', 'nowrap']}
        $left={[1, 1, 'auto']}
        $right={[1, 1, 1.5]}
        $bottom={[1, 1, 1.75]}
        $maxWidth={['auto', 'auto', '508px']}
      >
        <Box w={[1, 'auto']}>
          <Mascot
            animated={false}
            speechBubbleText="Hey there!"
            px={0}
            pt={1.5}
            ml={['50%', 0]}
            mr={-0.5}
            mb={[-1, 0]}
          />
        </Box>

        <Notice py={1} px={12 / 16}>
          <Heading h={4} mb={6 / 16}>
            We use cookies
          </Heading>
          <Text mb={1}>
            We use cookies that identify your device to provide you with a
            better online shopping experience, tailored to your preferences. To
            find out more and learn how to opt out, please visit our{' '}
            <Link style={{ textDecoration: 'underline' }} to="/privacy-policy/">
              Privacy Policy.
            </Link>
          </Text>
          <Box $display="flex" justifyContent="flex-end">
            <Button
              variant="outline"
              onClick={() => giveConsent('required')}
              mr={8 / 16}
            >
              Only required
            </Button>
            <Button onClick={() => giveConsent('full')}>Accept all</Button>
          </Box>
        </Notice>
      </NoticeWrapper>
    );
  }

  return null;
};

export default CookieNotice;
