import React from 'react';
import styled from 'styled-components';

import Flyout from '@/atoms/Flyout';
import Grid from '@/atoms/Grid';
import Box from '@/atoms/Box';
import Main from '../TopBar/Main';
import NestedNavigation from '../NestedNavigation';

const CloseButton = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: none;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 1.25rem;
    left: 0.75rem;
    width: 1rem;
    height: 1px;
    background-color: currentColor;
  }

  &:before {
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }
`;

const Drawer = ({
  item,
  isDrawerExpanded,
  i,
  closeNav,
  closeDrawer,
  mainProps,
  toggleDrawer,
}) => {
  return (
    <Flyout
      open={isDrawerExpanded[i]}
      $position="absolute"
      top={0}
      bottom={0}
      left={0}
      zIndex={1000}
      bg="white"
      direction="horizontal"
      withBorder={false}
      fullHeight={true}
    >
      <Grid.Container pt={2} pb={[1.5, 1.5, 2]} style={{ height: 'auto' }}>
        <CloseButton onClick={closeDrawer}></CloseButton>

        <Box mt={[0, 0, 0, 0.2]} mb={[1.75]}>
          <Main
            {...mainProps}
            layout="simple"
            toggleDrawer={i => toggleDrawer(i)}
            activeStyle="drawer"
            drawerOpen={i}
          />
        </Box>

        <Box as="nav" role="navigation">
          <NestedNavigation
            items={item}
            closeDrawer={closeDrawer}
            closeNav={closeNav}
          />
        </Box>
      </Grid.Container>
    </Flyout>
  );
};

export default Drawer;
