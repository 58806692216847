import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';

import { createLinkOrAnchorProps } from '@/utils/helpers';

const SafeLink = styled(Box).withConfig({
  shouldForwardProp: prop =>
    [
      'to',
      'state',
      'href',
      'target',
      'disabled',
      'children',
      'onClick',
      'type',
      'aria-label',
      'title',
      'rel',
      'name',
      'value',
      'activeClassName',
      'activeStyle',
    ].includes(prop),
})``;

const Link = props => {
  return <SafeLink {...createLinkOrAnchorProps(props)} />;
};

export default Link;

export const query = graphql`
  fragment referenceOrLinkData on SanityReferenceOrLink {
    title
    url
    targetBlank
    link {
      ... on SanityPage {
        path
      }
    }
  }
`;
