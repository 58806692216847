import { useLayoutEffect } from 'react';

const useLockBodyScroll = shouldLock => {
  useLayoutEffect(() => {
    if (shouldLock) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [shouldLock]);
};

export default useLockBodyScroll;
