import { useMediaQuery as rhUseMediaQuery } from '@react-hook/media-query';
import { useEffect, useState } from 'react';

export const useMediaQuery = query => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  const res = rhUseMediaQuery(query);

  return hasMounted ? res : undefined;
};
