import RCTooltip from 'rc-tooltip';
import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';

import theme from '@/styles/theme';

const TooltipArrow = styled.div`
  bottom: -4px;
  left: 50%;
  margin-left: -4px;
  position: absolute;
  width: 8px;
  height: 8px;
  border: 1px solid ${theme.colors.black};
  border-width: 0 1px 1px 0;
  background: ${theme.colors.white};
  transform: rotate(45deg);
`;

const TooltipContent = styled(Box)`
  border: 1px solid ${theme.colors.black};
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
`;

const Tooltip = ({ children, content }) => {
  return (
    <RCTooltip
      trigger={['hover', 'click', 'focus']}
      mouseLeaveDelay={0}
      placement="top"
      align={{
        points: ['bc', 'tr'],
        offset: [-7, -6],
      }}
      destroyTooltipOnHide
      overlayStyle={{ maxWidth: 260, padding: '0 4px' }}
      arrowContent={<TooltipArrow />}
      overlay={
        <TooltipContent p={0.75} bg="white">
          {content}
        </TooltipContent>
      }
    >
      {children}
    </RCTooltip>
  );
};

export default Tooltip;
