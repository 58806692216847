import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import Icon from '@/atoms/Icon';
import InputButton from '@/atoms/InputButton';
import Text from '@/atoms/Text';

const MobileQty = styled(Box)`
  width: ${props => (props.hasQty ? '1.5rem' : 0)};
  opacity: ${props => (props.hasQty ? 1 : 0)};
  overflow: hidden;
  transition: width 0.2s ease;
`;

const Button = ({ qty, ...props }) => {
  const hasQty = typeof qty !== 'undefined' && qty !== null;

  return (
    <InputButton to="/cart/" px={[0.625, 0.625, 0.75]} {...props}>
      <Icon type="cart" />

      <Box ml={0.475} display={['none', 'none', 'block']}>
        <Text t={-2} as="div" mb={-0.125}>
          Cart
        </Text>
        <Text as="div" $minWidth="3.5rem" bold>
          {hasQty ? `${qty} ${qty === 1 ? 'item' : 'items'}` : <>&nbsp;</>}
        </Text>
      </Box>

      <MobileQty
        display={['block', 'block', 'none']}
        hasQty={hasQty && qty !== 0}
        $width={hasQty && qty !== 0 ? '2rem' : 0}
      >
        <Text as="div" bold ml={0.475}>
          {qty}
        </Text>
      </MobileQty>
    </InputButton>
  );
};

export default Button;
