import styled from 'styled-components';

import Box from '@/atoms/Box';

import theme from '@/styles/theme';

const Container = styled(Box).attrs(props => ({
  px: props.px ?? theme.grid.outerMargin,
}))`
  position: ${props => props.position || props.$position || 'relative'};
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  ${typeof theme.grid.maxWidth !== 'undefined' &&
  `
    max-width: ${theme.grid.maxWidth}rem;
  `}
`;

export default Container;
