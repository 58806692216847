import React from 'react';
import styled from 'styled-components';

import Icon from '@/atoms/Icon';
import Link from '@/atoms/Link';

import { p, pLarge, pXSmall } from '@/styles/typography';

const SIZES = {
  default: {
    height: '2.25rem',
    typo: pLarge,
  },
  large: {
    height: '2.75rem',
    typo: pLarge,
  },
  medium: {
    height: '2rem',
    typo: p,
  },
  small: {
    height: '1.5rem',
    typo: pLarge,
  },
  xSmall: {
    height: '0.875rem',
    typo: pXSmall,
  },
  xLarge: {
    height: '2.75rem',
    typo: p,
  },
};

const StyledLink = styled(Link).attrs(props => ({
  display: props.$display || props.display || 'inline-flex',
}))`
  ${props => SIZES[props.size].typo}
  z-index: ${props => props.zIndex || 1};
  position: ${({ $position }) => $position ?? 'relative'};
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: center;
  height: ${props => SIZES[props.size].height};
  min-width: ${props => SIZES[props.size].height};
  border: 0;
  font-weight: bold;
  color: ${props => props.theme.colors[props.variant[props.size].color]};
  background: transparent;
  cursor: pointer;
  appearance: none;
  transition: all 0.2s ease;
  ${props =>
    props.size === 'xSmall' &&
    `  text-decoration: underline;
        text-underline-offset: 0.1875em;
        text-decoration-thickness: 1px;
  `}

  border-radius: 999px;
  background: ${props =>
    props.bg ?? props.theme.colors[props.variant[props.size].bg]};
  border: ${props =>
    props.theme.colors[props.variant[props.size].borderColor]
      ? `1px solid ${props.theme.colors[props.variant[props.size].borderColor]}`
      : 'none'};

  :hover,
  :focus {
    outline: none;
    border-color: ${props =>
      props.theme.colors[props.variant[props.size].hover?.borderColor]};
  }

  :disabled {
    cursor: initial;
    color: ${props =>
      props.theme.colors[props.variant[props.size].disabled.color]};
    background: ${props =>
      props.theme.colors[props.variant[props.size].disabled.bg]};
    border-color: ${props =>
      props.theme.colors[props.variant[props.size].hover?.borderColor]};
  }
`;

const VARIANTS = {
  primary: {
    default: {
      color: 'white',
      bg: 'black',
      disabled: {
        color: 'white',
        bg: 'gray20',
      },
    },
    large: {
      color: 'white',
      bg: 'black',
      disabled: {
        color: 'white',
        bg: 'gray20',
      },
    },
    small: {
      color: 'white',
      bg: 'black',
      disabled: {
        color: 'white',
        bg: 'gray20',
      },
    },
    xSmall: {
      color: 'black',
      borderColor: 'transparent',
      disabled: {
        color: 'gray20',
      },
    },
  },
  outline: {
    default: {
      color: 'black',
      borderColor: 'gray20',
      disabled: {
        color: 'black',
        bg: 'gray20',
      },
    },
    medium: {
      color: 'black',
      borderColor: 'gray20',
      disabled: {
        color: 'black',
        bg: 'gray20',
      },
    },
    xLarge: {
      color: 'black',
      borderColor: 'gray20',
      disabled: {
        color: 'black',
        bg: 'gray20',
      },
      hover: {
        borderColor: 'gray40',
      },
    },
  },
};

const Button = ({
  children,
  variant = 'primary',
  size = 'default',
  icon,
  iconSize = 'default',
  ...props
}) => {
  // specific settings for buttons that only have an icon
  const iconMargin = children ? { ml: -0.25, mr: 0.25 } : {};

  return (
    <StyledLink
      px={children ? 1 : 0}
      variant={VARIANTS[variant]}
      size={size}
      {...props}
    >
      {icon && <Icon type={icon} size={iconSize} {...iconMargin} />}

      {children}
    </StyledLink>
  );
};

export default Button;
