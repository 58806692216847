import React from 'react';
import { ThemeProvider } from 'styled-components';

import Box from '@/atoms/Box';
import CookieNotice from '@/components/CookieNotice';
import Footer from '@/components/Footer';
import Head from '@/components/Head';
import Header from '@/components/Header';
import NewsletterOverlay from '@/components/NewsletterOverlay';

import PageWrapper from './PageWrapper';

import { ApolloProvider } from '@/context/apolloContext';
import { CartProvider } from '@/context/cartContext';
import { RegionProvider } from '@/context/regionContext';
import GlobalStyles from '@/styles/global';
import theme from '@/styles/theme';

const Layout = ({ children, location, ...props }) => {
  return (
    <>
      <Head />
      <GlobalStyles />

      <ApolloProvider>
        <ThemeProvider theme={theme}>
          <CartProvider>
            <RegionProvider>
              <Box $position="relative">
                <Header
                  location={location}
                  showFilterBar={props.pageContext?.showFilterBar}
                />

                <PageWrapper pt={theme.page.paddingTop.default}>
                  {children}
                </PageWrapper>

                <Footer />

                <NewsletterOverlay />

                <CookieNotice />
              </Box>
            </RegionProvider>
          </CartProvider>
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
};

export default Layout;
