import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Headroom from 'react-headroom';
import styled from 'styled-components';

import Box from '@/atoms/Box';

import SecondaryBar from './SecondaryBar';
import TopBar from './TopBar';

import theme from '@/styles/theme';
import { media } from '@/styles/utils';
import { getBatchUrlId } from '@/utils/batches';
import { mapToSingleObject } from '@/utils/helpers';
import Drawer from './Drawer';

const Wrapper = styled(Box)`
  .headroom-wrapper {
    height: ${theme.header.topBarHeight[0]}rem !important;

    ${media.sm`
      height: ${theme.header.topBarHeight[1]}rem !important;
    `}
    ${media.md`
      height: ${theme.header.topBarHeight[2]}rem !important;
    `}
    ${media.lg`
      height: ${theme.header.topBarHeight[3]}rem !important;
    `}
    ${media.xl`
      height: ${theme.header.topBarHeight[4]}rem !important;
    `}
  }
`;

const FlyoutBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  height: calc(100dvh + 100px);
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  transition: opacity 300ms ease-in-out;
  opacity: ${props => (props.open ? 1 : 0)};
  pointer-events: ${props => (props.open ? 'auto' : 'none')};
`;

const Header = ({ location, showFilterBar, ...props }) => {
  const { navigation, info, latestBatch, nextBatch, filters } = useStaticQuery(
    graphql`
      query {
        navigation: allSanitySiteSettingsNavigation {
          edges {
            node {
              shopPage {
                path
              }
              headerNavigation {
                key: _key
                target {
                  ...referenceOrLinkData
                }
                children {
                  key: _key
                  type: _type
                  target {
                    title
                    url
                    targetBlank
                    link {
                      ... on SanityPage {
                        path
                      }
                    }
                  }
                  links {
                    key: _key
                    target {
                      ...referenceOrLinkData
                    }
                    children {
                      key: _key
                      target {
                        ...referenceOrLinkData
                      }
                    }
                  }
                }
              }
            }
          }
        }

        info: allSanitySiteSettingsInformation {
          edges {
            node {
              factoryLocation
            }
          }
        }

        filters: allSanityShopSettingsFilters {
          edges {
            node {
              ...filterData
            }
          }
        }

        latestBatch: allSanityBatch(
          filter: { isReleased: { eq: true } }
          sort: { releaseDate: DESC }
          limit: 1
        ) {
          edges {
            node {
              title {
                current
              }
            }
          }
        }

        nextBatch: allSanityBatch(
          filter: { isReleased: { eq: false } }
          sort: { releaseDate: ASC }
          limit: 1
        ) {
          edges {
            node {
              releaseDate
            }
          }
        }
      }
    `
  );

  const { headerNavigation, shopPage } = mapToSingleObject(navigation);
  const { factoryLocation } = mapToSingleObject(info);
  const { title: latestBatchTitle } = mapToSingleObject(latestBatch);
  const { releaseDate: nextBatchDate } = mapToSingleObject(nextBatch);

  const drawerItems = headerNavigation.map(item =>
    item.children.length ? item.children : null
  );
  const [isNavExpanded, setNavExpanded] = useState(false);
  const [isAnyDrawerExpanded, setAnyDrawerExpanded] = useState(false);
  const [isDrawerExpanded, setDrawerExpanded] = useState(
    drawerItems.map(() => false)
  );

  const toggleDrawer = i => {
    // create new array from isDrawerExpanded and toggle the drawer at index i
    const isIToggled = isDrawerExpanded[i];
    let toggledValues = isDrawerExpanded.map(() => false);
    if (!isIToggled) toggledValues[i] = true;
    const someDrawerExpanded = toggledValues.some(drawer => drawer === true);

    setDrawerExpanded(toggledValues);
    setAnyDrawerExpanded(someDrawerExpanded);
  };

  const closeDrawer = () => {
    setDrawerExpanded(drawerItems.map(() => false));
    setAnyDrawerExpanded(false);
  };

  const toggleNav = () => {
    setNavExpanded(prev => !prev);
  };

  const closeNav = () => {
    closeDrawer();
    setNavExpanded(false);
  };

  const latestBatchLink = `${shopPage.path}#${getBatchUrlId(
    latestBatchTitle.current
  )}`;

  const mainProps = {
    navigationItems: headerNavigation,
    drawerItems,
    location,
    factoryLocation,
    latestBatchTitle,
    latestBatchLink,
    nextBatchDate,
    closeNav,
  };

  return (
    <Wrapper>
      <Headroom style={{ zIndex: 1000 }} calcHeightOnResize={false} {...props}>
        <header style={{ pointerEvents: 'none' }}>
          <TopBar
            navigationItems={headerNavigation}
            drawerItems={drawerItems}
            location={location}
            factoryLocation={factoryLocation}
            latestBatchTitle={latestBatchTitle}
            latestBatchLink={latestBatchLink}
            closeNav={closeNav}
            toggleDrawer={i => toggleDrawer(i)}
            toggleNav={() => toggleNav()}
            nextBatchDate={nextBatchDate}
            withBorder={showFilterBar}
            isNavExpanded={isNavExpanded}
          />

          {showFilterBar && (
            <>
              <Box $height={['1.875rem', '1.875rem', '1.875rem', 0]} />
              <SecondaryBar
                availableFilters={mapToSingleObject(filters)}
                location={location}
                zIndex={-1}
                mt={['1px', '1px', '1px', 0]}
              />
            </>
          )}
        </header>
      </Headroom>

      <FlyoutBackground
        open={isAnyDrawerExpanded}
        onClick={closeDrawer}
      ></FlyoutBackground>

      {drawerItems.map((item, i) => {
        return item && item.length ? (
          <Drawer
            key={item[0].key}
            mainProps={mainProps}
            item={item}
            i={i}
            isDrawerExpanded={isDrawerExpanded}
            closeNav={closeNav}
            closeDrawer={closeDrawer}
            toggleDrawer={toggleDrawer}
          />
        ) : null;
      })}
    </Wrapper>
  );
};

export default Header;
