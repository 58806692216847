import { useLayoutEffect, useCallback, useState, useRef } from 'react';

import { debounce } from '@/utils/helpers';

function getRect(element) {
  if (!element) {
    return {
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0,
    };
  }

  return element.getBoundingClientRect();
}

const useMeasure = () => {
  const ref = useRef(null);
  const [rect, setRect] = useState(getRect(ref ? ref.current : null));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    debounce(() => {
      if (!ref.current) {
        return;
      }

      // Update client rect
      setRect(getRect(ref.current));
    }),
    [ref]
  );

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) {
      return null;
    }

    handleResize();
    let resizeObserver;

    if (typeof ResizeObserver === 'function') {
      resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(element);
    } else {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
        resizeObserver = null;
      } else {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [ref, handleResize]);

  return [ref, rect];
};

export default useMeasure;
