import styled from 'styled-components';

import Box from '@/atoms/Box';

import theme from '@/styles/theme';

const getOffset = o => {
  const convertOffset = offset => `${offset * 100}%`;

  if (typeof o === 'number') {
    return convertOffset(o);
  }

  return o.map(offset => convertOffset(offset));
};

const Item = styled(Box).attrs(({ o, or, flex, $flex }) => ({
  px: theme.grid.gutter.map(g => g / 2),
  ml: o ? getOffset(o) : 0,
  mr: or ? getOffset(or) : 0,
  flex: (flex || $flex) ?? '0 0 auto',
}))``;

export default Item;
