import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Box from '@/atoms/Box';
import Grid from '@/atoms/Grid';
import InvisibleLink from '@/atoms/InvisibleLink';
import Link from '@/atoms/Link';
import Logo from '@/atoms/Logo';
import Text from '@/atoms/Text';
import WithLabel from '@/atoms/WithLabel';
import ContactInformation from '@/components/ContactInformation';
import NewsletterForm from '@/components/NewsletterForm';

import Mascot from '../Mascot';

import { mapToSingleObject } from '@/utils/helpers';

const Footer = props => {
  const { general, footer } = useStaticQuery(graphql`
    query {
      general: allSanitySiteSettingsInformation {
        edges {
          node {
            companyLegalName
            customerServiceEmail
            factoryLocation
          }
        }
      }

      footer: allSanitySiteSettingsFooter {
        edges {
          node {
            newsletterText
            navigation {
              key: _key
              title
              links {
                key: _key
                ...referenceOrLinkData
              }
            }
            socialLinks {
              key: _key
              ...referenceOrLinkData
            }
          }
        }
      }
    }
  `);
  const { companyLegalName, factoryLocation } = mapToSingleObject(general);
  const { newsletterText, navigation, socialLinks } = mapToSingleObject(footer);

  const today = new Date();

  return (
    <Box as="footer" $borderTop="1px solid" $borderColor="gray10" {...props}>
      <Grid.Container pt={[1.5, 1.5, 2]} pb={[1.5, 1.5, 1]}>
        <Grid pb={[3, 3, 4]}>
          <Grid.Item w={[1, 1, 1 / 2]} pb={[3, 3, 0]}>
            <InvisibleLink to="/" $display="inline-block">
              <Logo w={['135px', '135px', '135px', '150px']} $height="auto" />
            </InvisibleLink>
          </Grid.Item>

          <Grid.Item w={[1, 1, 1 / 2]}>
            <Grid>
              <Grid.Item w={[1, 1, 1, 1, 1 / 2]}>
                <WithLabel label="Newsletter">
                  <Text $bold $maxWidth="24rem">
                    {newsletterText}
                  </Text>
                </WithLabel>
              </Grid.Item>
              <Grid.Item w={[1, 1, 1, 1, 1 / 2]}>
                <NewsletterForm mt={[1.25, 1.25, 1.25, 1.25, 0]} />
              </Grid.Item>
            </Grid>
          </Grid.Item>
        </Grid>

        <Grid pb={[3, 3, 5]}>
          <Grid.Item w={[1, 1, 1 / 2]} pb={[3, 3, 0]}>
            <ContactInformation showCustomerService />
          </Grid.Item>

          <Grid.Item w={[1, 1, 1 / 2]}>
            <Grid mb={-2}>
              {navigation.map(({ key, title, links }) => (
                <Grid.Item key={key} w={[1 / 2, 1 / 3, 1 / 2, 1 / 3]} mb={2}>
                  <WithLabel as="ul" label={title} $listStyle="none">
                    {links.map(
                      ({ key: linkKey, title: linkTitle, ...link }) => (
                        <Text key={linkKey} as="li" t={-1} $bold>
                          <Link {...link}>{linkTitle}</Link>
                        </Text>
                      )
                    )}
                  </WithLabel>
                </Grid.Item>
              ))}
            </Grid>
          </Grid.Item>
        </Grid>

        <Grid $alignItems="flex-end">
          <Grid.Item w={[1, 1, 1 / 2]} pb={[3, 3, 0]}>
            <Box as="ul" $listStyle="none" mb={0.25}>
              {socialLinks.map(({ key, title, ...link }) => (
                <Text
                  key={key}
                  as="li"
                  t={-1}
                  $bold
                  $display="inline-block"
                  mr={0.5}
                >
                  <Link {...link}>{title}</Link>
                </Text>
              ))}
            </Box>

            <Text t={-2}>
              © {today.getFullYear()} {companyLegalName}
            </Text>
          </Grid.Item>

          <Grid.Item w={[1, 1, 1 / 2]} $position="relative">
            <Mascot
              type="thanksBw"
              animated={false}
              px={0}
              pt={0}
              pb={[0.25, 0.25, 0]}
              $position={['static', 'static', 'absolute']}
              $left="-3.5rem"
              $bottom="-0.25rem"
            />

            <WithLabel label="Proudly produced at">
              <Text t={-2}>{factoryLocation}</Text>
            </WithLabel>
          </Grid.Item>
        </Grid>
      </Grid.Container>
    </Box>
  );
};

export default Footer;
