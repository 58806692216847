import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import Button from '@/atoms/Button';
import Text from '@/atoms/Text';

import theme from '@/styles/theme';

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.75rem;
  border: 1px solid
    ${({ disabled, error }) =>
      disabled
        ? theme.colors.gray10
        : error
        ? theme.colors.error
        : theme.colors.gray20};
  border-radius: 99px;
  :hover {
    border-color: ${({ disabled }) =>
      disabled ? theme.colors.gray10 : theme.colors.gray40};
  }
  :focus-within {
    border-color: ${({ error }) =>
      error ? theme.colors.error : theme.colors.black};
  }
`;

const InputField = styled(Text).attrs({ as: 'input' })`
  border-width: 0;
  border-radius: 0;
  color: ${theme.colors.black};
  background: transparent;
  -webkit-appearance: none;
  outline: none;
  transition: all 0.15s ease-out;
  flex: 1 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  ::placeholder {
    text-overflow: ellipsis;
    color: ${({ error }) => (error ? theme.colors.error : theme.colors.black)};
  }
  :focus ::placeholder {
    text-overflow: ellipsis;
    color: ${theme.colors.transparent};
  }
  :disabled ::placeholder {
    text-overflow: ellipsis;
    color: ${theme.colors.gray10};
  }
`;

const InputButton = styled(Button)`
  flex: 0 0 auto;
`;

const Input = forwardRef(
  (
    {
      type,
      disabled,
      placeholder,
      buttonText,
      onSubmit,
      error,
      onInputChange,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState('');

    const onClick = e => {
      if (onSubmit) {
        onSubmit(e);
      }
    };

    const onChange = event => {
      if (onInputChange) {
        onInputChange(event);
      }
      setValue(event.target.value);
    };

    return (
      <Wrapper
        {...props}
        error={error}
        disabled={disabled}
        pl={1.125}
        pr={0.25}
      >
        <InputField
          onChange={onChange}
          value={value}
          ref={ref}
          disabled={disabled}
          type={type}
          error={!!error}
          placeholder={placeholder}
          bold
          t={1}
          pr={0.25}
        />
        <InputButton disabled={disabled} type="submit" onClick={onClick}>
          {buttonText}
        </InputButton>
      </Wrapper>
    );
  }
);

export default Input;
