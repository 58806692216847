import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import Header from './Header';

import theme from '@/styles/theme';
import { media } from '@/styles/utils';

const ReactModalAdapter = ({ className, modalClassName, ...props }) => {
  return (
    <ReactModal
      className={modalClassName}
      portalClassName={className}
      bodyOpenClassName="portalOpen"
      {...props}
    />
  );
};

const StyledReactModal = styled(ReactModalAdapter).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  & .Overlay {
    z-index: 1000;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    ${media.md`
      left: 50%;
    `}

    ${media.lg`
      left: 57%;
    `}
  }

  & .Modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: 1px solid ${theme.colors.gray10};
    background: ${theme.colors.white};
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    outline: none;
  }

  &[class*='--after-open'] {
  }
  &[class*='--before-close'] {
  }
`;

export default StyledReactModal;

export { Header as ModalHeader };
