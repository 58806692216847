import React from 'react';

import PortableText from '@/atoms/PortableText';
import RichTextStyle from '@/atoms/RichTextStyle';

const RichText = ({ blocksRaw, textRaw, as = 'div', ...props }) => {
  return (
    <RichTextStyle as={as} {...props}>
      <PortableText blocks={blocksRaw || textRaw} />
    </RichTextStyle>
  );
};

export default RichText;
