const site = process.env.GATSBY_SANITY_PROJECT_ID;
const theme = {
  breakpoints: ['36em', '48em', '62em', '79em'],
  breakpointNames: ['xs', 'sm', 'md', 'lg', 'xl'],

  grid: {
    gutter: [1, 1, 1.5, 1.5, 1.5],
    outerMargin: [1, 1.5, 2, 2.5, 3],
    // maxWidth: 100,
  },

  colors: {
    transparent: 'rgba(255, 255, 255, 0)',
    white: '#fff',
    black: '#111',
    gray10: '#e7e7e7',
    gray20: '#cfcfcf',
    gray40: '#a0a0a0',
    green: '#40C800',
    amber: '#EBB820',
    red: '#DF3131',
    blue: '#4887F1',
    error: '#DF3131',
  },

  header: {
    topBarHeight: [4.125, 4.5, 5, 5.5, 6],
    secondaryBarHeight: [84 / 16, 84 / 16, 84 / 16, 60 / 16],
  },

  productPurchaseBar: {
    height: 4,
  },

  page: {
    safeArea: {
      top: [0.75, 1, 1, 1.25, 1.5],
      bottom: [0.75, 1, 1, 1.25, 1.5],
    },
    paddingTop: {
      default: [5, 5, 5, 6, 6],
      withSecondaryBar: [3.5, 3.5, 3.5, 4, 4], // delta to default
      narrow: [-4, -4, -1, -2, -5.5], // delta to default
    },
    titleMargin: [4, 4, 6],
    sectionMargin: [7.5, 7.5, 10],
    footerMargin: [7.5, 7.5, 10],
  },

  stories: {
    stageHeight: {
      desktop: 'calc(100vh - 4rem)', // productPurchaseBar height
    },
  },

  font: {
    sans: site === '06gfxth5' ? '"akzidenz-grotesk", sans-serif' : '"camera", sans-serif',
  },
};

export default theme;
