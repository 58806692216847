import React from 'react';
import Form from './Form';

const NewsletterForm = props => {

  return (
    <Form  {...props} />
  );
};

export default NewsletterForm;
