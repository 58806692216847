import styled from 'styled-components';

import Box from '@/atoms/Box';

import { h1, h2, h3, h4 } from '@/styles/typography';
import { visuallyHidden } from '@/styles/utils';

const styles = {
  1: h1,
  2: h2,
  3: h3,
  4: h4,
};

const Heading = styled(Box).attrs(props => ({
  as: props.as || `h${typeof props.h === 'number' ? props.h : 1}`,
}))`
  ${props => styles[props.h]}

  ${props => props.$hidden && visuallyHidden}

  hyphens: ${props => (props.hyphens ? 'auto' : 'none')};
  white-space: pre-line;
`;

export default Heading;
