import styled from 'styled-components';

import Link from '@/atoms/Link';

import theme from '@/styles/theme';

const Button = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.75rem;
  min-width: 2.75rem;
  border: 1px solid ${theme.colors.gray20};
  border-radius: 99px;
  color: ${theme.colors.black};
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  appearance: none;
  transition: all 0.2s ease;
  outline: none;

  :hover,
  :focus {
    border-color: ${theme.colors.gray40};
  }

  :active {
    border-color: ${theme.colors.black};
  }
`;

export default Button;
