import React from 'react';
import styled from 'styled-components';

import Icon from '@/atoms/Icon';
import Link from '@/atoms/Link';
import IndexItemTooltip from '@/components/IndexItemTooltip';

import theme from '@/styles/theme';

const StyledLink = styled(Link)`
  && {
    text-decoration: none;
    font-weight: bold;
    white-space: nowrap;

    :hover,
    :focus {
      opacity: 1;
    }
  }
`;

const ArrowIcon = styled(Icon).attrs({ type: 'arrowLink', size: 'small' })`
  display: inline-block;
  margin-top: -1px;
  margin-left: 0.175rem;
  border: 1px solid ${theme.colors.gray10};
  border-radius: 50%;
  vertical-align: middle;
`;

const TooltipLink = ({ to, modal = true, tooltipData, children, ...props }) => {
  const linkProps = modal ? { state: { modal: true } } : {};

  const LinkWithIcon = () => (
    <StyledLink to={to} {...linkProps} {...props}>
      {children}

      <ArrowIcon />
    </StyledLink>
  );

  return tooltipData ? (
    <IndexItemTooltip {...tooltipData}>
      <span>
        <LinkWithIcon />
      </span>
    </IndexItemTooltip>
  ) : (
    <LinkWithIcon />
  );
};

export default TooltipLink;
