import {
  ApolloClient,
  ApolloProvider as Provider,
  createHttpLink as CreateHttpLink,
  InMemoryCache,
} from '@apollo/client';
import fetch from 'isomorphic-fetch';
import React from 'react';

const client = new ApolloClient({
  cache: new InMemoryCache(),

  link: new CreateHttpLink({
    uri: `https://${process.env.GATSBY_SHOPIFY_SHOP_NAME}.myshopify.com/api/2021-04/graphql.json`,
    headers: {
      'X-Shopify-Storefront-Access-Token':
        process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
      Accept: 'application/graphql',
    },
    fetch,
  }),
});

export const ApolloProvider = ({ children }) => (
  <Provider client={client}>{children}</Provider>
);
