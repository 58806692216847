import Cookies from 'js-cookie';
import React, { createContext, useContext, useEffect, useState } from 'react';

const RegionContext = createContext(null);

export const RegionProvider = props => {
  const [region, setRegion] = useState(null);

  useEffect(() => {
    // Cookie is set on each request via Netlify Edge function
    // region can be EU or JP
    const storedRegion = Cookies.get('region');

    if (storedRegion) {
      setRegion(storedRegion);
    }
  }, [setRegion]);

  const value = {
    region,
  };

  return <RegionContext.Provider value={value} {...props} />;
};

export const useRegionContext = () => {
  return useContext(RegionContext);
};
