import styled, { css } from 'styled-components';
import {
  position,
  space,
  layout,
  flexbox,
  color,
  typography,
  border,
} from 'styled-system';

import { forceArray } from '@/utils/helpers';

const custom = props => css`
  ${props.listStyle && `list-style: ${props.listStyle};`}
`;

/**
 * All props can/should be used as transient props to prevent them
 * from appearing in the DOM (e.g. $color)
 */

/**
 * We're not using the standard space scale but transforming our values
 * to strings to prevent them from being interpreted by styled-system
 */
const keysToBeTransformed = [
  // custom
  'listStyle',
  // space
  'm',
  'margin',
  'mt',
  'marginTop',
  'mr',
  'marginRight',
  'mb',
  'marginBottom',
  'ml',
  'marginLeft',
  'mx',
  'my',
  'p',
  'padding',
  'pt',
  'paddingTop',
  'pr',
  'paddingRight',
  'pb',
  'paddingBottom',
  'pl',
  'paddingLeft',
  'px',
  'py',
  // size/layout
  'height',
  'minWidth',
  'maxWidth',
  'minHeight',
  'maxHeight',
  'size',
  // position
  'left',
  'bottom',
  'top',
  'right',
];

/**
 * these props are renamed before they're handed to styled-system in order
 * to be interpreted correctly
 */
const keysToBeRenamed = {
  w: 'width',
};

const transformValue = value => {
  return forceArray(value).map(v => (typeof v === 'number' ? `${v}rem` : v));
};

export const transformProps = props => {
  return Object.entries(props).reduce((acc, [key, value]) => {
    const finalKey = key.startsWith('$')
      ? key.substring(1)
      : keysToBeRenamed[key] || key;

    return {
      ...acc,
      [finalKey]: keysToBeTransformed.includes(finalKey)
        ? transformValue(value)
        : value,
    };
  }, {});
};

const Box = styled.div`
  ${props => position(transformProps(props))}
  ${props => space(transformProps(props))}
  ${props => layout(transformProps(props))}
  ${props => flexbox(transformProps(props))}
  ${props => color(transformProps(props))}
  ${props => typography(transformProps(props))}
  ${props => border(transformProps(props))}

  //custom
  ${props => custom(transformProps(props))}
`;

export default Box;
