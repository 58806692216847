import React from 'react';

import Button from '@/atoms/Button';

import theme from '@/styles/theme';

const Header = ({ closeTo }) => {
  const buttonProps = {
    to: closeTo,
    state: {
      noScroll: true,
    },
    $position: 'fixed',
    top: theme.page.safeArea.top,
    zIndex: 100,
    variant: 'outline',
    size: 'xLarge',
    bg: 'white',
  };

  return (
    <>
      <Button
        {...buttonProps}
        left={theme.grid.outerMargin}
        icon="back"
        $display={['inline-flex', 'inline-flex', 'none']}
      />

      <Button
        {...buttonProps}
        right={theme.grid.outerMargin}
        icon="close"
        $display={['none', 'none', 'inline-flex']}
      />
    </>
  );
};

export default Header;
