// import React from 'react';

// import Box from '@/atoms/Box';
// import Image from '@/components/Image';

// import { getImageFromRaw } from '@/utils/images';

const Block = ({ node }) => {
  if (!node.image || !node.image.asset || !node.image.asset._ref) {
    return null;
  }

  // const image = getImageFromRaw(node.image);

  // return (
  //   <Box as="figure">
  //     <Image presentationWidth={1} {...image} />
  //   </Box>
  // );

  return null;
};

export default Block;
