const isBrowser = typeof window !== 'undefined';

/**
 * Get Uri based on content type
 */
const PREFIXES = {
  page: null,
  product: 'products',
  men: 'men',
  women: 'women',
  indexItem: 'manufacturing-index',
};

const getUri = (slug, type = 'page') => {
  const slugs = Array.isArray(slug) ? slug : [slug],
    parts = [PREFIXES[type], ...slugs].filter(Boolean),
    result = parts.length ? `/${parts.join('/')}/` : '/',
    resultStrippedOfBatchNo = type === `product`
      ? result.replace(`batch-no-`, ``)
      : result;
  return resultStrippedOfBatchNo;
};

module.exports.getUri = getUri;

/**
 * Get query params from search string
 */
const getQueryParams = searchString => {
  const pairs = searchString.replace('?', '').split('&');

  return pairs.reduce((acc, cur) => {
    const [key, value] = cur.split('=');

    if (key && value && key.length > 0 && value.length > 0) {
      return {
        ...acc,
        [key]: value,
      };
    }

    return acc;
  }, {});
};

module.exports.getQueryParams = getQueryParams;

/**
 * Check if a path is currently active (e.g. to check active nav-item)
 */
const isPathActive = (path, uri) =>
  path === '/' ? uri === '/' : uri.startsWith(path);

module.exports.isPathActive = isPathActive;

/**
 * Get parent path in URI
 */
const getParentPath = uri => {
  const endsWithSlash = uri.charAt(uri - 1) === '/';
  const sanitizedUri = endsWithSlash ? uri.substring(0, uri.length - 1) : uri;

  return sanitizedUri.substring(0, sanitizedUri.lastIndexOf('/') + 1);
};

module.exports.getParentPath = getParentPath;

/**
 * Get back destination
 * Go to previous location in history if same origin,
 * otherwise go to parent path in uri
 * can be used in combination with navigate(destination);
 */
const getBackDestination = location => {
  if (!isBrowser) return null;

  const isSameOrigin = location.state !== null;
  const destination = isSameOrigin ? -1 : getParentPath(location.pathname);

  return destination;
};

module.exports.goBack = getBackDestination;
