import PortableText from '@sanity/block-content-to-react';
import React from 'react';

import Box from '@/atoms/Box';

const Block = props => {
  const { style = 'normal' } = props.node;

  // headings
  if (/^h\d/.test(style)) {
    return <Box as={style}>{props.children}</Box>;
  }

  // paragraphs
  if (style === 'normal') {
    return <Box as="p">{props.children}</Box>;
  }

  // large
  if (style === 'large') {
    return (
      <Box as="p" className="large">
        {props.children}
      </Box>
    );
  }

  // small
  if (style === 'small') {
    return (
      <Box as="p" className="small">
        {props.children}
      </Box>
    );
  }

  // Fall back to default handling
  return PortableText.defaultSerializers.types.block(props);
};

export default Block;
