import imageUrlBuilder from '@sanity/image-url';
import { getGatsbyImageData } from 'gatsby-source-sanity';

import theme from '@/styles/theme';
import { forceArray } from '@/utils/helpers';

/**
 * Sanity get image asset from raw field
 */
export const getImageFromRaw = image => {
  const imageAssetId = image?.asset?._ref;

  if (!imageAssetId) {
    return null;
  }

  const imageAsset = getGatsbyImageData(
    imageAssetId,
    { maxWidth: 2400 },
    {
      projectId: process.env.GATSBY_SANITY_PROJECT_ID,
      dataset: process.env.GATSBY_SANITY_DATASET,
    }
  );

  return { asset: { gatsbyImageData: imageAsset } };
};

/**
 * Sanity Image URL Helper
 */
const imageBuilder = imageUrlBuilder({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
});

export const sanityImage = source => {
  return imageBuilder.image(source);
};

/**
 * Get responsive sizes string for images based on grid and theme breakpoints
 */
const getSrcsetSize = width => {
  return typeof width === 'number' ? `${Math.round(width * 100)}vw` : width;
};

export const getSrcsetSizes = widths => {
  const w = forceArray(widths);
  const lastSize = w.slice(-1)[0];

  const smallerSizes = w.slice(0, w.length - 1);
  let breakpointQueries;

  if (smallerSizes.length > 0) {
    breakpointQueries = [
      smallerSizes.map(
        (width, i) =>
          `(max-width: ${theme.breakpoints[i]}) ${getSrcsetSize(width)}`
      ),
      getSrcsetSize(lastSize),
    ];
  } else {
    breakpointQueries = [getSrcsetSize(lastSize)];
  }

  return breakpointQueries.join(', ');
};
