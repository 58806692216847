import React from 'react';

import Box from '@/atoms/Box';
import Text from '@/atoms/Text';

import theme from '@/styles/theme';

const IndexItemId = ({ indexNo, categoryNo, children, ...props }) => {
  return (
    <Box
      $display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
      borderBottom="1px solid"
      {...props}
    >
      <Box>{children}</Box>

      <Box $display="flex" alignItems="flex-end">
        <Box $height="5px" w="1px" bg={theme.colors.black} />
        <Text mr={0.5} ml={0.25} t="batchSmall">
          {categoryNo?.toString().padStart(2, '0')}
        </Text>
        <Box $height="5px" w="1px" bg={theme.colors.black} />
        <Text mr={0.375} ml={0.25} t="batchSmall">
          {indexNo?.toString().padStart(4, '0')}
        </Text>
      </Box>
    </Box>
  );
};

export default IndexItemId;
