import styled, { keyframes } from 'styled-components';

import Box from '@/atoms/Box';

// we're delaying the display of the notice s we're sure it doesn't flash up
// before state from localstorage is loaded.
const fadeIn = keyframes`
  0% {
    opacity: 0;

  }
  100% {
    opacity: 1;

  }
`;

export const NoticeWrapper = styled(Box)`
  position: fixed;
  z-index: 1000;
  display: flex;
  opacity: 0;
  animation: 0.2s ${fadeIn} ease-out forwards;
  animation-delay: 1s;
`;

export const Notice = styled(Box)`
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  background: ${props => props.theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black};
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
`;
