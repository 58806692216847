import React from 'react';

import Box from '@/atoms/Box';
import Text from '@/atoms/Text';

const WithLabel = ({
  label,
  bold = false,
  labelDisplay,
  children,
  ...props
}) => {
  return (
    <Box {...props}>
      <Text t={-2} as="h6" $bold={bold} mb={0} $display={labelDisplay}>
        {label}
      </Text>

      {children}
    </Box>
  );
};

export default WithLabel;
