import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import IndexItemIcon from '@/atoms/IndexItemIcon';
import Text from '@/atoms/Text';

import theme from '@/styles/theme';

const ButtonWrapper = styled(Box)`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.75rem;
  min-width: 2.75rem;
  border: 1px solid
    ${({ isSelected }) =>
      isSelected ? theme.colors.black : theme.colors.transparent};
  border-radius: 99px;
  color: ${theme.colors.black};
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  appearance: none;
  transition: all 0.2s ease;
  outline: none;
  @media (hover: hover) {
    :hover,
    :focus {
      border-color: ${({ isSelected }) =>
        isSelected ? theme.colors.black : theme.colors.gray40};
    }
  }
  :active {
    border-color: ${theme.colors.black};
  }
`;

const FilterButton = ({
  filterType,
  children,
  icon,
  iconStyle,
  iconBorder,
  ...props
}) => {
  return (
    <ButtonWrapper
      pl={filterType === 'color' ? 0.625 : 0.75}
      pr={0.75}
      {...props}
    >
      {icon && (
        <Box mr={0.5}>
          <IndexItemIcon
            icon={icon}
            iconStyle={iconStyle}
            iconBorder={iconBorder}
          />
        </Box>
      )}
      <Text t={-1}>{children}</Text>
    </ButtonWrapper>
  );
};

export default FilterButton;
