// useScreenSize.js
import { useState, useEffect } from 'react';

const useScreenSize = () => {
  const isBrowser = typeof window !== "undefined"

  const [screenSize, setScreenSize] = useState({
    width: isBrowser ? window.innerWidth : 0,
    height: isBrowser ? window?.innerHeight : 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: isBrowser ? window.innerWidth : 0,
        height: isBrowser ? window?.innerHeight : 0,
      });
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isBrowser]);

  return screenSize;
};

export default useScreenSize;
