import { graphql, useStaticQuery } from 'gatsby';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Button from '@/atoms/Button';
import Heading from '@/atoms/Heading';
import RichText from '@/atoms/RichText';
import Mascot from '@/components/Mascot';
import NewsletterForm from '@/components/NewsletterForm';
import { NoticeWrapper, Notice } from '@/components/Notice';

import useLocalStorage from '@/hooks/useLocalStorage';
import { media } from '@/styles/utils';
import { mapToSingleObject } from '@/utils/helpers';

const Overlay = styled(NoticeWrapper)`
  transform: translateY(-50%);

  ${media.sm`
    transform: translate(-50%, -50%);
  `}
`;

const NewsletterOverlay = props => {
  const { settings } = useStaticQuery(graphql`
    query {
      settings: allSanitySiteSettingsInformation {
        edges {
          node {
            newsletterOverlayActive
            newsletterOverlayDelay
            newsletterTitle
            newsletterDescription {
              textRaw: _rawText(resolveReferences: { maxDepth: 6 })
            }
            newsletterMascot {
              ...mascotData
            }
          }
        }
      }
    }
  `);

  const {
    newsletterOverlayActive: isEnabled,
    newsletterOverlayDelay: delay,
    newsletterTitle: title,
    newsletterDescription: description,
    newsletterMascot: mascot,
  } = mapToSingleObject(settings);

  const [isActive, setActive] = useState(false);

  const [isViewed, setViewed] = useLocalStorage(
    'overlay_newsletter_viewed',
    undefined
  );

  useEffect(() => {
    if (typeof isViewed === 'undefined') {
      setViewed(false);
    }
  }, [isViewed, setViewed]);

  useEffect(() => {
    let timeout;

    if (typeof isViewed === 'undefined') {
      setViewed(false);
    }

    if (!isViewed) {
      timeout = setTimeout(() => {
        setViewed(true);
        setActive(true);
      }, delay * 1000);
    }

    return () => timeout && clearTimeout(timeout);
  }, [isViewed, setViewed, setActive, delay]);

  const handleClose = () => setActive(false);

  if (!isEnabled || !isActive) {
    return null;
  }

  return (
    <Overlay
      $left={[1, '50%']}
      $right={[1, 'auto']}
      $top={['50%', '45%']}
      w={['auto', '480px']}
      {...props}
    >
      <Notice pt={[2, 2.5]} pb={[3, 3.5]} px={[0.75, 1.5, 3]}>
        <Button
          $position="absolute"
          right={-0.75}
          top={-0.75}
          icon="close"
          variant="outline"
          size="medium"
          bg="white"
          onClick={handleClose}
        />

        {title && (
          <Heading h={3} mb={0.5}>
            {title}
          </Heading>
        )}

        {description?.textRaw && (
          <RichText $maxWidth="24rem" textRaw={description.textRaw} mb={1} />
        )}

        <NewsletterForm />

        {mascot && (
          <Mascot
            $position="absolute"
            $left="20%"
            $bottom="100%"
            animated={false}
            mb={-1}
            {...mascot}
          />
        )}
      </Notice>
    </Overlay>
  );
};

export default NewsletterOverlay;
