import { graphql } from 'gatsby';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/styles/utils';
import { forceArray } from '@/utils/helpers';
import { getSrcsetSizes } from '@/utils/images';

const overrideAspectRatios = ratios =>
  ratios
    ? css`
        ${ratios.map(
          ({ ar, media: mq }) => css`
            @media ${mq} {
              padding-top: ${ar * 100}% !important;
            }
          `
        )}
      `
    : null;

const StyledImage = styled(GatsbyImage).withConfig({
  shouldForwardProp: prop =>
    !['containMobile', 'containDesktop', '_key', '_type'].includes(prop),
})`
  width: 100%;
  height: ${p => (p.containMobile ? '100%' : 'auto')};
  font-size: 0;

  ${media.md`
    height: ${p => (p.containDesktop ? '100%' : 'auto')};
  `}

  & > div:first-child {
    display: ${p => (p.containMobile ? 'none' : 'block')} !important;

    ${media.md`
      display: ${p => (p.containDesktop ? 'none' : 'block')} !important;
    `}

    ${p => overrideAspectRatios(p.$aspectRatios)}
  }

  & > img {
    width: 100%;
    height: ${p => (p.containMobile ? '100%' : 'auto')} !important;

    ${media.md`
      height: ${p => (p.containDesktop ? '100%' : 'auto')} !important;
    `}

    :-moz-loading {
      visibility: hidden;
    }
  }
`;

// add custom srcset sizes
const transformAsset = (asset, srcsetSizes) => ({
  ...asset.gatsbyImageData,
  images: {
    ...asset.gatsbyImageData.images,
    fallback: {
      ...asset.gatsbyImageData.images.fallback,
      sizes: srcsetSizes,
    },
  },
});

const Image = memo(
  ({
    asset,
    presentationWidth = 1,
    containMobile = false,
    containDesktop = false,
    ...props
  }) => {
    if (!asset) return null;
    const [firstAsset, ...restAssets] = forceArray(asset);

    const isSvg =
      firstAsset.mimeType === 'image/svg+xml' ||
      firstAsset.gatsbyImageData?.images?.sources[0]?.type === 'image/svg';

    const srcsetSizes = getSrcsetSizes(presentationWidth);
    let imageProps = {
      containMobile,
      containDesktop,
    };

    // don't use gatsby-image if this is an svg
    // (we want to keep a similar markup though)
    if (isSvg) {
      const imageUrl =
        firstAsset.url ||
        firstAsset.gatsbyImageData?.images?.sources[0]?.srcSet.split(' ')[0];

      if (imageUrl) {
        return (
          <StyledImage as="div" {...props}>
            <img
              src={imageUrl}
              width={firstAsset.gatsbyImageData?.width}
              height={firstAsset.gatsbyImageData?.height}
              alt={firstAsset.alt || ''}
            />
          </StyledImage>
        );
      }
    }

    // art directed image (array)
    if (restAssets.length > 0) {
      const transformedRestAssets = restAssets.map(
        ({ media: mq, ...assetItem }) => ({
          media: mq,
          image: transformAsset(assetItem, srcsetSizes),
        })
      );

      imageProps = {
        ...imageProps,
        alt: firstAsset.alt || '',
        image: withArtDirection(
          transformAsset(firstAsset, srcsetSizes),
          transformedRestAssets
        ),
        $aspectRatios: restAssets.map(assetItem => ({
          media: assetItem.media,
          ar: assetItem.gatsbyImageData.height,
        })),
      };

      // normal image
    } else {
      imageProps = {
        ...imageProps,
        alt: firstAsset.alt || '',
        image: transformAsset(firstAsset, srcsetSizes),
      };
    }

    return <StyledImage {...imageProps} {...props} />;
  },
  () => true
);

export default Image;

export const query = graphql`
  fragment imageData on SanityImage {
    asset {
      alt
      gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      mimeType
      url
    }
  }

  fragment imageData_16_9 on SanityImage {
    asset {
      alt
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: NONE
        aspectRatio: 1.7778
      )
      mimeType
      url
    }
  }

  fragment imageDataConstrained on SanityImage {
    asset {
      alt
      gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      mimeType
      url
    }
  }
`;
