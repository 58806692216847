import styled from 'styled-components';

import Box from '@/atoms/Box';

import GridContainer from './Container';
import GridItem from './Item';

import theme from '@/styles/theme';

const Grid = styled(Box).attrs(p => ({
  mx: p.mx ?? theme.grid.gutter.map(g => -g / 2),
  $position: p.position ?? 'relative',
  $display: p.$display ?? 'flex',
  flexWrap: (p.flexWrap || p.$flexWrap) ?? 'wrap',
}))``;

Grid.Item = GridItem;
Grid.Container = GridContainer;

export default Grid;
