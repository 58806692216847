import SanityPortableText from '@sanity/block-content-to-react';
import React from 'react';

import Link from '@/atoms/Link';
import TooltipLink from '@/atoms/TooltipLink';
import Block from '@/components/Blocks/Block';
import Image from '@/components/Blocks/Image';
import List from '@/components/Blocks/List';

import { createLinkOrAnchorProps } from '@/utils/helpers';
import { getImageFromRaw } from '@/utils/images';
import { getUri } from '@/utils/routing';

const serializers = {
  container: ({ children }) => {
    return <>{children}</>;
  },
  marks: {
    link: ({ children, mark }) => {
      const linkProps = mark.link
        ? {
            ...mark,
            link: {
              path: getUri(mark.link.slug?.current, mark.link._type),
            },
          }
        : mark;

      return <Link {...createLinkOrAnchorProps(linkProps)}>{children[0]}</Link>;
    },

    indexReference: ({ children, mark }) => {
      const { reference } = mark;

      if (!reference) {
        return children[0];
      }

      const uri = getUri(
        `${reference.category.slug.current}/${reference.slug.current}`,
        'indexItem'
      );

      const icon = getImageFromRaw(reference.icon);

      return (
        <TooltipLink tooltipData={{ ...reference, icon }} to={uri}>
          {children[0]}
        </TooltipLink>
      );
    },
  },
  types: {
    block: Block,
    blockImage: Image,
  },
  list: List,
};

const PortableText = ({ blocks, ...props }) => {
  return (
    <SanityPortableText blocks={blocks} serializers={serializers} {...props} />
  );
};

export default PortableText;
