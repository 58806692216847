import { graphql } from 'gatsby';
import React from 'react';

import Box from '@/atoms/Box';
import IndexItemIcon from '@/atoms/IndexItemIcon';
import Text from '@/atoms/Text';
import IndexItemId from '@/components/IndexItemId';

import ModalRoutingLink from '@/utils/modal-routing/ModalRoutingLink';

const IndexItem = ({
  title,
  indexNo,
  category,
  icon,
  iconStyle,
  iconBorder,
  largeIcon,
  path,
  to,
  ...props
}) => {
  const linkProps = path || to ? { as: ModalRoutingLink, to: path || to } : {};

  return (
    <Box {...props}>
      <Box {...linkProps}>
        <IndexItemId indexNo={indexNo} categoryNo={category?.indexNo} mb={0.25}>
          <IndexItemIcon
            mb={0.375}
            size="xlarge"
            icon={largeIcon ?? icon}
            iconStyle={iconStyle}
            iconBorder={iconBorder}
          />
        </IndexItemId>

        <Text t={-1} $bold>
          {title}
        </Text>
      </Box>
    </Box>
  );
};
export default IndexItem;

export const query = graphql`
  fragment indexItemData on SanityIndexItem {
    path
    indexNo
    title
    category {
      indexNo
    }
    ...indexItemLargeIconData
    ...indexItemIconData
  }
`;
