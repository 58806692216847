import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import Box from '@/atoms/Box';

const VARIANTS = {
  positive: {
    bg: 'green',
  },
  caution: {
    bg: 'amber',
  },
  negative: {
    bg: 'red',
  },
  neutral: {
    bg: 'gray20',
  },
};

const SIZES = {
  small: {
    $width: '0.5rem',
    $height: '0.5rem',
  },
  default: {
    $width: '0.75rem',
    $height: '0.75rem',
  },
};

const blinkAnimation = keyframes`
  50% {
    opacity: 0;
  }
`;

const Circle = styled(Box)`
  border-radius: 99px;

  ${props =>
    props.blink &&
    css`
      animation: ${blinkAnimation} 1s step-start 0s infinite;
    `}
`;

const Indicator = ({
  variant = 'neutral',
  size = 'default',
  blink = false,
  ...props
}) => {
  const variantProps = VARIANTS[variant] || VARIANTS.neutral;
  const sizeProps = SIZES[size] || SIZES.default;

  return <Circle blink={blink} {...variantProps} {...sizeProps} {...props} />;
};

export default Indicator;
