import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import Heading from '@/atoms/Heading';
import Link from '@/atoms/Link';
import StatusIndicator from '@/atoms/StatusIndicator';
import Text from '@/atoms/Text';
import WithLabel from '@/atoms/WithLabel';

import { mapToSingleObject } from '@/utils/helpers';

const Info = styled(WithLabel)`
  :last-child {
    margin-bottom: 0;
  }
`;

const ContactInformation = ({
  showAddress,
  showCustomerService,
  customerServiceSubline,
  ...props
}) => {
  const { general } = useStaticQuery(graphql`
    query {
      general: allSanitySiteSettingsInformation {
        edges {
          node {
            fullAddress
            customerServiceEmail
            customerServiceInfo
          }
        }
      }
    }
  `);

  const {
    fullAddress,
    customerServiceEmail,
    customerServiceInfo,
  } = mapToSingleObject(general);

  return (
    <Box {...props}>
      {showAddress && (
        <Info label="Address" mb={1.5}>
          <Text t={1} $bold whiteSpace="pre-line">
            {fullAddress}
          </Text>
        </Info>
      )}

      {showCustomerService && (
        <Info label="Customer service" mb={1.5}>
          {customerServiceSubline && (
            <Text mb={2 / 16} mt={4 / 16}>
              {customerServiceSubline}
            </Text>
          )}
          <Heading h={3} as="p">
            <Link href={`mailto:${customerServiceEmail}`} target="_blank">
              <StatusIndicator
                as="span"
                variant="positive"
                blink
                $display="inline-block"
                mr={0.5}
              />
              {customerServiceEmail}
            </Link>
          </Heading>

          <Text $bold mt={0.25}>
            {customerServiceInfo}
          </Text>
        </Info>
      )}
    </Box>
  );
};

export default ContactInformation;

export const query = graphql`
  fragment contactInformationData on SanityContactInformation {
    showAddress
    showCustomerService
  }
`;
