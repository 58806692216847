import React from 'react';

import InvisibleLink from '@/atoms/InvisibleLink';

import ModalRoutingContext from './ModalRoutingContext';

const ModalRoutingLink = ({ to, asModal = true, state, ...rest }) => (
  <ModalRoutingContext.Consumer>
    {({ closeTo }) => (
      <InvisibleLink
        to={to}
        state={{
          ...state,
          modal: asModal,
          noScroll: to === closeTo,
        }}
        {...rest}
      />
    )}
  </ModalRoutingContext.Consumer>
);

export default ModalRoutingLink;
