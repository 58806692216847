import { useSpring } from '@react-spring/core';
import { animated as springAnimated } from '@react-spring/web';
import { graphql } from 'gatsby';
import React, { memo, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import Icon from '@/atoms/Icon';
import Link from '@/atoms/Link';
import Text from '@/atoms/Text';

import MascotDefault from '@/assets/images/mascot-default.svg';
import MascotHanging from '@/assets/images/mascot-hanging.svg';
import MascotMagnify from '@/assets/images/mascot-magnify.svg';
import MascotNotepad from '@/assets/images/mascot-notepad.svg';
import MascotShipping from '@/assets/images/mascot-shipping.svg';
import MascotThanksBw from '@/assets/images/mascot-thanks-bw.svg';
import MascotThanks from '@/assets/images/mascot-thanks.svg';
import MascotWashing from '@/assets/images/mascot-washing.svg';
import theme from '@/styles/theme';

const MASCOTS = {
  default: {
    image: MascotDefault,
    layout: {
      $left: '55%',
      $bottom: '105%',
      $width: '220%',
      pointer: '65%',
    },
  },
  hanging: {
    image: MascotHanging,
    layout: {
      $left: '50%',
      $bottom: '102%',
      $width: '220%',
      pointer: '55%',
    },
  },
  magnify: {
    image: MascotMagnify,
    layout: {
      $left: '60%',
      $bottom: '103%',
      $width: '250%',
      pointer: '65%',
    },
  },
  notepad: {
    image: MascotNotepad,
    layout: {
      $left: '50%',
      $bottom: '104%',
      $width: '220%',
      pointer: '60%',
    },
  },
  shipping: {
    image: MascotShipping,
    layout: {
      $left: '55%',
      $bottom: '103%',
      $width: '200%',
      pointer: '45%',
    },
  },
  thanks: {
    image: MascotThanks,
    layout: {
      $left: '55%',
      $bottom: '107%',
      $width: '290%',
      pointer: '55%',
    },
  },
  thanksBw: {
    image: MascotThanksBw,
    layout: {
      $left: '55%',
      $bottom: '107%',
      $width: '290%',
      pointer: '55%',
    },
  },
  washing: {
    image: MascotWashing,
    layout: {
      $left: '73%',
      $bottom: '98%',
      $width: '140%',
      pointer: '45%',
    },
  },
};

const Image = styled(springAnimated.img)`
  pointer-events: none;
`;

const SpeechBubble = styled(Box).attrs({
  t: 'speechbubble',
  $bold: true,
  hyphens: true,
})`
  position: relative;
  display: inline-flex;
  background: ${theme.colors.white};
  border-radius: 0.5rem;
  border: 3px solid ${theme.colors.black};
  align-items: center;

  ::after {
    content: '';
    position: absolute;
    display: block;
    left: ${p => p.pointer};
    top: 100%;
    width: 28px;
    height: 14px;
    margin-left: -14px;
    margin-top: -2px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyOCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBjbGlwLXBhdGg9InVybCgjY2xpcDApIj48cGF0aCBkPSJNMCAyVjBoMjh2MkwxNS43IDEyaC0zLjRMMCAyeiIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Ik0wIDJoMS44YTUgNSAwIDAxMy41IDEuNUwxMiAxMGEzLjMgMy4zIDAgMDA0LjYgMGw2LjYtNi41QTUgNSAwIDAxMjYuNiAySDI4djNoLTEuNGEyIDIgMCAwMC0xLjQuNmwtNi42IDYuNmE2LjMgNi4zIDAgMDEtOC44IDBMMy4yIDUuNkEyIDIgMCAwMDEuOCA1SDBWMnoiIGZpbGw9IiMxMTEiLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJjbGlwMCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGgyOHYxNEgweiIvPjwvY2xpcFBhdGg+PC9kZWZzPjwvc3ZnPg==)
      no-repeat left center;
  }
`;

const SpeechBubbleLink = styled(Link)`
  color: ${theme.colors.white};
  background: ${theme.colors.black};
  align-items: center;
  display: flex;
  height: 28px;
  flex: 1 0 28px;
  border-radius: 50%;
`;

const SpeechBubbleContainer = springAnimated(Box);

const Mascot = ({
  type = 'default',
  speechBubbleText,
  speechBubbleLink,
  speechBubbleWidth,
  animated = true,
  controlledVisibile = false,
  visible = false,
  ...props
}) => {
  const { pointer, ...layout } = MASCOTS[type].layout;

  const [isVisible, setVisible] = useState(false);
  const [containerRef, inView] = useInView({
    rootMargin: '-35% 0px -35% 0px',
  });

  useEffect(() => {
    if (!isVisible && inView) {
      setVisible(inView);
    }
  }, [isVisible, inView]);

  const finalIsVisible = controlledVisibile ? visible : isVisible;
  const { x } = useSpring({
    from: { x: !animated ? 1 : 0 },
    x: finalIsVisible ? 1 : 0,
    config: { duration: 300 },

    cancel: !animated,
  });

  const { y } = useSpring({
    from: { y: !animated ? 1 : 0 },
    y: finalIsVisible ? 1 : 0,
    config: { duration: 300 },
    delay: 200,
    cancel: !animated,
  });

  return (
    <Box pt={3.5} px={2} $overflow="hidden" ref={containerRef} {...props}>
      <Box $position="relative" $display="inline-block">
        {speechBubbleText && (
          <Box
            $position="absolute"
            {...layout}
            $width={speechBubbleWidth || layout.$width}
          >
            <SpeechBubbleContainer
              style={{
                transformOrigin: 'left bottom',
                scale: y.to({
                  range: [0, 0.75, 1],
                  output: [0, 0.53, 0.5],
                }),
                translateX: '-50%',
              }}
            >
              <SpeechBubble px={0.375} py={0.25} pointer={pointer}>
                <Text t="speechbubble" bold hyphens>
                  {speechBubbleText}
                </Text>
                {speechBubbleLink && (
                  <SpeechBubbleLink
                    target={speechBubbleLink.targetBlank ?? '_blank'}
                    to={speechBubbleLink.link.path}
                    alt={speechBubbleLink.title}
                  >
                    <Icon size="default" type="info" />
                  </SpeechBubbleLink>
                )}
              </SpeechBubble>
            </SpeechBubbleContainer>
          </Box>
        )}
        <Image
          src={MASCOTS[type].image}
          alt="Index® mascot"
          style={{
            scale: x.to({
              range: [0, 0.75, 1],
              output: [0, 1.1, 1],
            }),
          }}
        />
      </Box>
    </Box>
  );
};

export default memo(Mascot);

export const query = graphql`
  fragment mascotData on SanityMascot {
    type
    speechBubbleText
    speechBubbleLink {
      ...referenceOrLinkData
    }
  }
`;
