import Collapsible from '@/components/Collapsible';
import styled from 'styled-components';

import theme from '@/styles/theme';
import { media } from '@/styles/utils';

const Flyout = styled(Collapsible)`
  position: ${props =>
    props.direction === 'horizontal' ? 'fixed' : 'relative'};
  width: 100%;
  ${props => props.direction === 'horizontal' && 'max-width: 0;'}
  ${props =>
    props.direction === 'horizontal' &&
    'transition: max-width 300ms ease-in-out;'}
  ${props =>
    props.direction === 'horizontal' && props.open && 'max-width: 30rem;'}
  ${props => props.direction === 'horizontal' && props.open && 'height: 100vh;'}
  ${props =>
    props.direction === 'horizontal' && props.open && 'height: 100dvh;'}
  ${props => props.open && props.fullHeight && 'height: 100vh;'}
  pointer-events: all;
  overflow: hidden;

  ${media.belowsm`
    border-bottom: 1px solid
      ${props =>
        props.open || props.withBorder ? theme.colors.gray10 : 'transparent'};
    transition: border-color 0.2s ease;

  `}

  .headroom--scrolled && {
    border-bottom-color: ${theme.colors.gray10};
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: ${props => (props.direction === 'horizontal' ? '1px' : '100%')};
    height: ${props => (props.direction === 'horizontal' ? '100%' : '1px')};
    background-color: ${props =>
      props.open || props.withBorder ? theme.colors.gray10 : 'transparent'};
    transition: background-color 0.2s ease;
  }
`;

export default Flyout;
