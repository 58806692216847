import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { subscribe } from 'klaviyo-subscribe';

import Box from '@/atoms/Box';
import Input from '@/atoms/Input';
import Text from '@/atoms/Text';

import { validateEmail } from '@/utils/helpers';

const Message = styled(Text).attrs({ t: -1 })`
  position: absolute;
  top: 100%;
  left: 1.125rem;
  right: 1.125rem;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: all 0.1s ease-out;

  a {
    text-decoration: underline;
    text-underline-offset: 0.1875em;
    text-decoration-thickness: 1px;
  }
`;

const Form = ({ message, ...props }) => {
  const emailField = useRef(null);
  const [status, setStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();
    const email = emailField.current.value;

    setErrorMessage(null);

    if (email && validateEmail(email)) {
      // subscribe({ EMAIL: email });
      const listId = 'RwYhxg';
      subscribe(listId, email).then(response => {
        if (response?.success) {
          setStatus('success');
        }
      });
    } else {
      setErrorMessage('Valid email is required');
    }
  };

  useEffect(() => {
    if (status === 'error') {
      setErrorMessage(message);
    }
  }, [status, message]);

  const onInputChange = () => {
    setErrorMessage(null);
  };

  return (
    <Box
      as="form"
      $position="relative"
      noValidate
      onSubmit={handleSubmit}
      {...props}
    >
      <Input
        onInputChange={onInputChange}
        ref={emailField}
        error={errorMessage}
        type="email"
        placeholder="Email ..."
        disabled={['sending', 'success'].includes(status)}
        buttonText="Sign up"
      />

      <Message mt={0.5} isVisible={status === 'success'}>
        Thank you for signing up. Please check your mailbox to confirm your
        subscription.
      </Message>

      <Message
        $color="error"
        mt={0.25}
        isVisible={errorMessage}
        dangerouslySetInnerHTML={{ __html: errorMessage }}
      />
    </Box>
  );
};

export default Form;
