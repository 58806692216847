import styled from 'styled-components';

import Box from '@/atoms/Box';

import {
  pLarge,
  p,
  pSmall,
  pXSmall,
  batchLarge,
  batch,
  batchSmall,
  navItem,
  navItemSmall,
  navItemLarge,
  speechbubble,
} from '@/styles/typography';

const styles = {
  '-2': pXSmall,
  '-1': pSmall,
  0: p,
  1: pLarge,
  batchSmall,
  batch,
  batchLarge,
  navItem,
  navItemSmall,
  navItemLarge,
  speechbubble,
};

const NavigationItem = styled(Box).attrs(props => ({
  as: props.as || 'p',
  t: props.t || 0,
  whiteSpace: props.whiteSpace || 'normal',
}))`
  ${props => styles[props.t < 0 ? props.t.toString() : props.t]}

  hyphens: ${props => (props.hyphens ? 'auto' : 'none')};
  white-space: ${props => props.whiteSpace};

  ${props =>
    (props.bold || props.$bold) &&
    `
    font-weight: bold;
  `}

  :last-child {
    margin-right: 0;
  }

  a,
  button {
    text-decoration: ${props => (props.isActive ? 'underline' : 'none')};
    text-decoration-thickness: 2px;
    text-underline-offset: 0.175em;
  }

  & > button {
    appearance: none;
    background: none;
    border: none;
    font-weight: inherit;
    cursor: pointer;
  }
`;

export default NavigationItem;
