exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-templates-manufacturing-index-item-js": () => import("./../../../src/templates/ManufacturingIndexItem.js" /* webpackChunkName: "component---src-templates-manufacturing-index-item-js" */),
  "component---src-templates-manufacturing-index-js": () => import("./../../../src/templates/ManufacturingIndex.js" /* webpackChunkName: "component---src-templates-manufacturing-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-product-overview-js": () => import("./../../../src/templates/ProductOverview.js" /* webpackChunkName: "component---src-templates-product-overview-js" */)
}

