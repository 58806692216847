import styled from 'styled-components';

import Text from '@/atoms/Text';

import { h1, h2, h3, h4, pSmall, pXSmall, pLarge } from '@/styles/typography';

const RichTextStyle = styled(Text)`
  h1 {
    ${h1}
    margin-top: 0;
    margin-bottom: 0;

    :not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  p + h1,
  ol + h1,
  ul + h1 {
    margin-top: 2.5rem;
  }

  h2 {
    ${h2}
    margin-top: 0;
    margin-bottom: 0;

    :not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  p + h2,
  ol + h2,
  ul + h2 {
    margin-top: 2.5rem;
  }

  h3 {
    ${h3}
    margin-top: 0;
    margin-bottom: 0;

    :not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  p + h3,
  ol + h3,
  ul + h3 {
    margin-top: 1.5rem;
  }

  h4 {
    ${h4}
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  p + h4,
  ol + h4,
  ul + h4 {
    margin-top: 1.5rem;
  }

  h5 {
    ${pSmall}
    margin-top: 0;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid currentColor;
  }

  p + h5,
  ol + h5,
  ul + h5 {
    margin-top: 2.5rem;
  }

  h6 {
    ${pXSmall}
    margin-top: 0;
    margin-bottom: 0;
  }

  p + h6,
  ol + h6,
  ul + h6 {
    margin-top: 1.5rem;
  }

  p,
  ol,
  ul {
    margin-bottom: 0.5rem;
  }

  p.small {
    ${pSmall}
  }

  p.large {
    ${pLarge}
    font-weight: bold;
  }

  ol {
    padding-left: 1.75rem;

    > li {
      padding-left: 0.5rem;
    }
  }

  ul {
    list-style: disc;
    padding-left: 2rem;

    > li {
      padding-left: 0.25rem;
    }
  }

  a {
    text-decoration: underline;
    text-underline-offset: 0.1875em;
    text-decoration-thickness: 1px;

    :hover,
    :focus {
      opacity: 0.8;
    }
  }
`;

export default RichTextStyle;
