import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';

import Button from './Button';

import { GET_CHECKOUT } from '@/api/checkout';
import { CartContext } from '@/context/cartContext';

const CartButton = props => {
  const { checkoutId } = useContext(CartContext);

  const { loading, data } = useQuery(GET_CHECKOUT, {
    variables: { checkoutId },
    skip: !checkoutId,
    partialRefetch: true,
  });

  const qty =
    loading || !data
      ? 0
      : data?.node?.lineItems?.edges.reduce(
          (acc, { node }) => acc + node.quantity,
          0
        );

  return <Button qty={qty} {...props} />;
};

export default CartButton;
