/*
read: https://medium.com/@david.gilbertson/icons-as-react-components-de3e33cb8792
use: https://jakearchibald.github.io/svgomg/
*/
import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';

const Svg = styled(Box).attrs({ as: 'svg' })`
  box-sizing: content-box;
  fill: currentColor;
  height: auto;
`;

const SIZES = {
  default: 24,
  small: 12,
};

const ICONS = {
  cart:
    '<path d="M3.31 3.994l1.813 1.45v11.6h14.592v-1.755H6.877V4.602L4.405 2.624 3.31 3.994zm6.976 15.6a1.714 1.714 0 11-3.429 0 1.714 1.714 0 013.429 0zm6 1.715a1.714 1.714 0 100-3.428 1.714 1.714 0 000 3.428zM8.573 6.738h11.143v6.857H8.572V6.738z" fill="currentColor"/>',

  navHamburger:
    '<path fill="currentColor" d="M0 3h24v2H0zM0 11h24v2H0zM0 19h24v2H0z"/>',

  navClose:
    '<path d="M10.625 12L1 21.625 2.375 23 12 13.375 21.625 23 23 21.625 13.375 12 23 2.375 21.625 1 12 10.625 2.375 1 1 2.375 10.625 12z" fill="currentColor"/>',

  chevronUp: '<path d="M6 3.5l-4 4h8l-4-4z" fill="currentColor"/>',

  chevronDown: '<path d="M6 8.5l4-4H2l4 4z" fill="currentColor"/>',

  plus: '<path d="M5 7v3h2V7h3V5H7V2H5v3H2v2h3z" fill="currentColor"/>',

  minus: '<path d="M10 7H2V5h8v2z" fill="currentColor"/>',

  close:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M12 13.429l4.986 4.985 1.428-1.428L13.43 12l4.985-4.986-1.428-1.428L12 10.57 7.015 5.586l-1.43 1.428L10.573 12l-4.986 4.986 1.429 1.428L12 13.43z" fill="currentColor"/>',

  arrowLink:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M6.89 4.25H4.203v-1h4.5v4.5h-1V4.991L4.028 8.667l-.778-.778L6.89 4.25z" fill="currentColor"/>',

  back:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.086 12l6.957-6.957 1.414 1.414L9.914 12l5.543 5.543-1.414 1.414L7.086 12z" fill="currentColor"/>',

  info:
    '<path d="M15.7,4h-3.5v3.3h3.5V4z M15.5,17.7V8.8h-6v2.3h3v6.6H9V20h10v-2.3H15.5z" fill="currentColor" />',
};

const Icon = ({ type, size = 'default', width, height, ...props }) => {
  return (
    <Svg
      viewBox={`0 0 ${width ?? SIZES[size]} ${height ?? SIZES[size]}`}
      width={width || SIZES[size]}
      height={height || SIZES[size]}
      {...props}
      dangerouslySetInnerHTML={{ __html: ICONS[type] }} // eslint-disable-line react/no-danger
    />
  );
};

export default Icon;
