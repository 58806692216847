import React from 'react';

import Layout from '@/components/Layout';

import modalRoutingOnClientEntry from '@/utils/modal-routing/onClientEntry';
import modalRoutingShouldUpdateScroll from '@/utils/modal-routing/shouldUpdateScroll';
import modalRoutingWrapPageElement from '@/utils/modal-routing/wrapPageElement';

export const onClientEntry = async (_args, opts = {}) => {
  // polyfill intersetion observer
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
  modalRoutingOnClientEntry(_args, opts);
};

export const shouldUpdateScroll = modalRoutingShouldUpdateScroll;
export const wrapPageElement = ({ props }, opts = {}) => {
  const modalProps = {};

  return (
    <Layout {...props}>
      {modalRoutingWrapPageElement({ props }, { ...opts, modalProps })}
    </Layout>
  );
};
