import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import fonts from '@/styles/fonts';
import { mapToSingleObject } from '@/utils/helpers';

const Head = ({
  title,
  meta = {
    robotsIndex: true,
    metaTitle: null,
    metaDescription: null,
    ogImage: null,
  },
  ...props
}) => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allSanitySiteSettingsMeta {
        edges {
          node {
            meta {
              robotsIndex
              metaTitle
              metaDescription
              ogImage {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const { meta: defaultMeta } = mapToSingleObject(data);

  // if this is not the production environment, we don't want to index the site
  // noindex set in general siteSettings has priority over page-specific setting
  let indexSafe = 'noindex,nofollow';

  if (process.env.GATSBY_STAGE === 'production') {
    indexSafe =
      defaultMeta.robotsIndex && meta.robotsIndex
        ? 'index,follow'
        : 'noindex,follow';
  }

  const ogImage = meta.ogImage || defaultMeta?.ogImage;
  const site = process.env.GATSBY_SANITY_PROJECT_ID;

  return (
    <Helmet
      titleTemplate={`%s — ${defaultMeta?.metaTitle}`}
      defaultTitle={defaultMeta?.metaTitle}
      title={meta.metaTitle || title}
      {...props}
    >
      <html lang="en" />

      <style type="text/css">{fonts}</style>

      <meta name="robots" content={indexSafe} />

      <meta
        name="description"
        content={meta.metaDescription || defaultMeta?.metaDescription}
      />

      {site === '06gfxth5' && (
        <link rel="stylesheet" href="https://use.typekit.net/qrj6ehz.css" />
      )}
      {site === 'or62nxkj' && (
        <link rel="stylesheet" href="/fonts/style.css" />
      )}

      {ogImage && <meta property="og:image" content={ogImage.asset.url} />}
    </Helmet>
  );
};

export default Head;

export const query = graphql`
  fragment metaData on SanityMeta {
    robotsIndex
    metaTitle
    metaDescription
    ogImage {
      asset {
        url
      }
    }
  }
`;
