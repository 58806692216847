import React, { useState, useEffect } from 'react';
import Box from '@/atoms/Box';
import Link from '@/atoms/Link';
import NavigationItem from '@/atoms/NavigationItem';
import { isPathActive } from '@/utils/routing';
import Collapsible from '@/components/Collapsible';

const Navigation = ({
  navigationItems,
  drawerItems,
  drawerOpen,
  location,
  closeNav,
  toggleDrawer,
  activeStyle,
  isSmallScreen,
  ...props
}) => {
  const [activePath, setActivePath] = useState(null);

  useEffect(() => {
    if (location?.state && !location.state.modal) {
      setActivePath(location.pathname);
    }
  }, [location?.pathname, location?.state]);

  const handleParentClicked = i => {
    if (!isSmallScreen) {
      toggleDrawer(i);
    }
  };

  const renderNavigationItems = (items, isDrawer, showWithChildren = true) =>
    items?.map(({ key, target, children }, i) => {
      const hasChildren = children && children.length > 0;
      const shouldShow = isDrawer
        ? showWithChildren
          ? hasChildren
          : !hasChildren // Show based on children flag
        : true; // Show all items in regular nav

      const isActive =
        target.link && activePath && isPathActive(target.link.path, activePath);

      return (
        <NavigationItem
          key={key}
          as="li"
          t="navItem"
          mr={[0, 0, 0, 1, 2]}
          mb={[0.75, 0.75, 0.75, 0]}
          activeStyle={activeStyle}
          isActive={isActive}
          $display={shouldShow ? 'block' : 'none'}
        >
          {children && children.length ? (
            <>
              <Link onClick={() => handleParentClicked(i)} isActive={isActive}>
                {target.title}
              </Link>
              {isSmallScreen && (
                <Collapsible open={true} pt={[0.5]} pb={[1.5]}>
                  {children.map(child => (
                    <Box key={child.key} as="ul" $listStyle="none">
                      <NavigationItem
                        as="li"
                        t="navItemSmall"
                        mr={[0, 0, 0, 1, 2]}
                        mb={[0.5, 0.5, 0.5, 0]}
                      >
                        <Link
                          link={child.target.link}
                          url={child.target.url}
                          targetBlank={child.target.targetBlank}
                          onClick={() => closeNav()}
                        >
                          {child.target.title}
                        </Link>
                      </NavigationItem>
                    </Box>
                  ))}
                </Collapsible>
              )}
            </>
          ) : (
            <Link
              link={target.link}
              url={target.url}
              targetBlank={target.targetBlank}
              onClick={() => closeNav()}
              isActive={isActive}
            >
              {target.title}
            </Link>
          )}
        </NavigationItem>
      );
    });

  return (
    <Box as="nav" role="navigation" {...props}>
      {activeStyle === 'drawer' ? (
        <Box>
          <Box
            as="ul"
            $listStyle="none"
            $display={['block', 'block', 'block', 'flex']}
            mb={[-0.75, -0.75, -0.75, 0]}
            $position="absolute"
            style={{ bottom: '-87vh', right: 0 }}
          >
            {renderNavigationItems(navigationItems, true, false)}
          </Box>
          <Box
            as="ul"
            $listStyle="none"
            $display={['block', 'block', 'block', 'flex']}
            mb={[-0.75, -0.75, -0.75, 0]}
          >
            {renderNavigationItems(navigationItems, true, true)}
          </Box>
        </Box>
      ) : (
        <Box
          as="ul"
          $listStyle="none"
          $display={['block', 'block', 'block', 'flex']}
          mb={[-0.75, -0.75, -0.75, 0]}
        >
          {renderNavigationItems(navigationItems, false, false)}
        </Box>
      )}
    </Box>
  );
};

export default Navigation;
