// import Lausanne300Woff1 from '@/assets/fonts/Lausanne-300.woff';
// import Lausanne300Woff2 from '@/assets/fonts/Lausanne-300.woff2';

// export default `
//   @font-face {
//     font-family: 'Lausanne';
//     font-weight: normal;
//     font-style: normal;
//     font-stretch: normal;
//     src: url(${Lausanne300Woff2}) format('woff2'),
//       url(${Lausanne300Woff1}) format('woff');
//   }
// `;

const fonts = ``;

export default fonts;
