import { gql } from '@apollo/client';

const checkoutContentFragment = gql`
  fragment CheckoutContent on Checkout {
    id
    completedAt
    ready
    webUrl
    lineItemsSubtotalPrice {
      amount
      currencyCode
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    totalTaxV2 {
      amount
      currencyCode
    }
    lineItems(first: 250) {
      edges {
        node {
          id
          title
          quantity
          variant {
            id
            image {
              transformedSrc(
                crop: CENTER
                maxWidth: 64
                maxHeight: 64
                scale: 3
              )
            }
            # quantityAvailable maps to inventoryQuantity in the Admin API
            quantityAvailable
            # Following field not available in Admin API, which is used
            # by updated gatsby-source-shopify in place of Storefront API.
            # Apollo still uses Storefront API here.
            priceV2 {
              amount
              currencyCode
            }
            # In Admin API, only price is available
            # price
            selectedOptions {
              value
            }
            product {
              handle
            }
          }
        }
      }
    }
  }
`;

// create a new empty checkout
export const CREATE_NEW_CHECKOUT = gql`
  mutation {
    checkoutCreate(
      input: {
        allowPartialAddresses: true
        shippingAddress: { country: "Germany", city: "Berlin" }
      }
    ) {
      checkout {
        id
      }
    }
  }
`;

// get an existing checkout by id
export const GET_CHECKOUT = gql`
  query($checkoutId: ID!) {
    node(id: $checkoutId) {
      ... on Checkout {
        ...CheckoutContent
      }
    }
  }
  ${checkoutContentFragment}
`;

// add line item
export const ADD_LINE_ITEM = gql`
  mutation($checkoutId: ID!, $variantId: ID!, $quantity: Int!) {
    checkoutLineItemsAdd(
      checkoutId: $checkoutId
      lineItems: [{ quantity: $quantity, variantId: $variantId }]
    ) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutContent
      }
    }
  }
  ${checkoutContentFragment}
`;

// update line item (adjust quantity)
export const UPDATE_LINE_ITEM = gql`
  mutation($checkoutId: ID!, $lineItemId: ID!, $quantity: Int!) {
    checkoutLineItemsUpdate(
      checkoutId: $checkoutId
      lineItems: [{ id: $lineItemId, quantity: $quantity }]
    ) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutContent
      }
    }
  }
  ${checkoutContentFragment}
`;

// remove line item
export const REMOVE_LINE_ITEM = gql`
  mutation($checkoutId: ID!, $lineItemId: ID!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: [$lineItemId]
    ) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutContent
      }
    }
  }
  ${checkoutContentFragment}
`;

// remove multiple line items
export const REMOVE_LINE_ITEMS = gql`
  mutation($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutContent
      }
    }
  }
  ${checkoutContentFragment}
`;

// optimistic response helpers
const getOptimisticResponse = (oldData, lineItems, subtotal) => {
  return {
    __typename: 'Mutation',
    userErrors: {
      __typename: 'UserError',
      message: '',
      field: '',
    },
    checkout: {
      ...oldData.node,
      lineItemsSubtotalPrice: {
        ...oldData.node.lineItemsSubtotalPrice,
        amount: subtotal,
      },
      lineItems: {
        ...oldData.node.lineItems,
        edges: lineItems,
      },
    },
  };
};

const getTotal = lineItems =>
  lineItems.reduce(
    (total, { node }) =>
      total + node.quantity * parseFloat(node.variant.priceV2.amount),
    0
  );

// optimistic response for UPDATE_LINE_ITEM
export const optimisticUpdateLineItem = (oldCartData, lineItemId, quantity) => {
  const updatedLineItems = oldCartData.node.lineItems.edges.map(edge =>
    edge.node.id === lineItemId
      ? {
          ...edge,
          node: {
            ...edge.node,
            quantity,
          },
        }
      : edge
  );

  return {
    checkoutLineItemsUpdate: {
      ...getOptimisticResponse(
        oldCartData,
        updatedLineItems,
        getTotal(updatedLineItems)
      ),
    },
  };
};

// optimistic response for REMOVE_LINE_ITEM
export const optimisticRemoveLineItem = (oldCartData, lineItemId) => {
  const updatedLineItems = oldCartData.node.lineItems.edges.filter(
    edge => edge.node.id !== lineItemId
  );

  return {
    checkoutLineItemsRemove: {
      ...getOptimisticResponse(
        oldCartData,
        updatedLineItems,
        getTotal(updatedLineItems)
      ),
    },
  };
};
