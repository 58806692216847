import React from 'react';
import styled from 'styled-components';

import Icon from '@/atoms/Icon';
import InputButton from '@/atoms/InputButton';

const Button = styled(InputButton)`
  border: 0;
`;

const Toggle = ({ icon = 'navHamburger', ...props }) => {
  return (
    <Button {...props}>
      <Icon type={icon} />
    </Button>
  );
};

export default Toggle;
