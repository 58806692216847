import React from 'react';

import Text from '@/atoms/Text';
import Tooltip from '@/atoms/Tooltip';
import IndexItem from '@/components/IndexItem';

const IndexItemTooltip = ({
  category,
  icon,
  iconStyle,
  iconBorder,
  children,
  indexNo,
  title,
  shortDescription,
}) => {
  return (
    <Tooltip
      content={
        <>
          <IndexItem
            icon={icon}
            iconStyle={iconStyle}
            iconBorder={iconBorder}
            category={category}
            indexNo={indexNo}
            title={title}
          />

          {shortDescription && (
            <Text t={-1} mt={0.25}>
              {shortDescription}
            </Text>
          )}
        </>
      }
    >
      {children}
    </Tooltip>
  );
};

export default IndexItemTooltip;
