import styled from 'styled-components';

import Link from './Link';

const InvisibleLink = styled(Link)`
  text-decoration: inherit;
  color: inherit;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

export default InvisibleLink;
