import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState, createContext, useCallback } from 'react';

import {
  CREATE_NEW_CHECKOUT,
  GET_CHECKOUT,
  REMOVE_LINE_ITEMS,
} from '@/api/checkout';

const isBrowser = typeof window !== 'undefined';

const defaultContext = {
  checkoutId: null,
  getLineItemForVariant: null,
};

export const CartContext = createContext(defaultContext);

// Check for an existing cart
const existingCheckoutId = isBrowser
  ? localStorage.getItem('shopify_checkout_id')
  : null;

export const CartProvider = ({ children }) => {
  const [checkoutId, setCheckoutId] = useState(existingCheckoutId);

  const setCheckout = useCallback(
    id => {
      if (isBrowser) {
        localStorage.setItem('shopify_checkout_id', id);
      }
      setCheckoutId(id);
    },
    [setCheckoutId]
  );

  const [removeLineItems] = useMutation(REMOVE_LINE_ITEMS);
  const [createNewCheckout] = useMutation(CREATE_NEW_CHECKOUT, {
    onCompleted: data => {
      console.log('new checkout created', data.checkoutCreate.checkout.id);
      setCheckout(data.checkoutCreate.checkout.id);
    },
  });

  const { data: cartData } = useQuery(GET_CHECKOUT, {
    name: 'existingCheckout',
    skip: checkoutId === null,
    variables: {
      checkoutId,
    },
    onError: async () => {
      // createNewCheckout();
    },
    onCompleted: async checkout => {
      if (typeof checkout !== 'undefined' && checkout.node !== null) {
        // Make sure this cart hasn’t already been purchased
        if (!checkout.node.completedAt) {
          // Remove all products that are not available anymore from cart (variant = null)
          const unavailableLineItems = checkout.node.lineItems.edges.filter(
            ({ node }) => node.variant === null
          );

          if (unavailableLineItems.length) {
            const unavailableLineItemIds = unavailableLineItems.map(
              ({ node }) => node.id
            );

            await removeLineItems({
              variables: {
                checkoutId: checkout.node.id,
                lineItemIds: unavailableLineItemIds,
              },
            });
          }

          setCheckout(checkout.node.id);
          return;
        }
      }

      createNewCheckout();
    },
  });

  useEffect(() => {
    if (!checkoutId) {
      createNewCheckout();
    }
  }, [checkoutId, createNewCheckout]);

  const getLineItemForVariant = selectedVariant => {
    const lineItems = cartData?.node?.lineItems?.edges;
    const lineItemForVariant = lineItems?.find(
      item => item.node.variant.id === selectedVariant?.id
    );
    return lineItemForVariant;
  };

  return (
    <CartContext.Provider value={{ checkoutId, getLineItemForVariant }}>
      {children}
    </CartContext.Provider>
  );
};
